<template>
  <layout width="420">
    <template slot="header">
      Edit account info
    </template>
    <template>
      <v-form autocomplete="false">
        <form-subheader title="General" />
        <v-text-field v-model="localEntity.login" label="Login *" disabled />

        <form-subheader title="Default groups" />
        <v-select
          v-model="defaultEditorgroup"
          label="Default editors group"
          item-text="name"
          item-value="id"
          :items="localEntity.usersToGroups"
          class="ma-0"
        />
        <v-select
          v-model="defaultUsergroup"
          label="Default users group"
          item-text="name"
          item-value="id"
          :items="groups"
          class="ma-0"
        />
        <v-select
          v-model="defaultReadergroup"
          label="Default readers group"
          disabled
          item-text="name"
          item-value="id"
          :items="groups"
          class="ma-0"
        />
        <form-subheader title="Contact info" />
        <v-text-field
          v-model="localEntity.mName"
          label="Name"
          :error="!!errors.mName"
          :error-messages="errors.mName"
          clearable
        />
        <v-text-field
          v-model="localEntity.mEmail"
          autocomplete="false"
          label="Email"
          :rules="[rules.email]"
          :error="!!errors.mEmail"
          :error-messages="errors.mEmail"
          clearable
        />
        <v-text-field
          v-model="localEntity.mPhone"
          autocomplete="false"
          label="Phone number"
          :error="!!errors.mPhone"
          :error-messages="errors.mPhone"
          clearable
        />
        <v-text-field
          v-model="localEntity.mExternalId"
          autocomplete="false"
          label="Second ID"
          :error="!!errors.mExternalId"
          :error-messages="errors.mExternalId"
          clearable
        />
        <select-chips
          v-model="localEntity.mTags"
          multiple
          label="Tags"
          item-text="name"
          item-value="id"
          chips
          class="ma-0"
          @drop-item="handleDropChip(localEntity.tags, $event, 'string')"
        />

        <form-subheader title="Photo" />
        <form ref="formPicture" class="user_photo">
          <div
            v-if="!mPicture"
            class="text-center d-flex justify-center align-center "
            style="width: 100%; height: 120px;"
          >
            <img src="/img/empty-media.svg" alt="Select media file" />
          </div>
          <v-img v-else contain :src="mPicture" :height="240" />
          <div class="upload">
            <v-btn
              text
              dense
              :color="options.bgColor"
              :class="{ shadow: options.shadow }"
              small
              fab
              @click="$refs.photo.click()"
            >
              <input
                id="photo"
                ref="photo"
                type="file"
                accept="image/*"
                style="display: none"
                @change="filePick($event, 'photo')"
              />
              <v-icon :color="options.textColor">
                mdi-cloud-upload
              </v-icon>
            </v-btn>

            <!--            <v-btn-->
            <!--              v-if="isNew && mPicture"-->
            <!--              text-->
            <!--              dense-->
            <!--              :color="options.bgColor"-->
            <!--              :class="{ shadow: options.shadow }"-->
            <!--              class="ml-3"-->
            <!--              small-->
            <!--              fab-->
            <!--              type="reset"-->
            <!--              @click="clearFile('photo', $refs.formPicture)"-->
            <!--            >-->
            <!--              <v-icon :color="options.textColor">-->
            <!--                mdi-delete-->
            <!--              </v-icon>-->
            <!--            </v-btn>-->
          </div>
        </form>
        <div transition="scroll-y-transition">
          <div
            v-if="imagesError.mPicture.length > 0"
            class="v-messages theme--light error--text mt-2"
            role="alert"
          >
            <div class="v-messages__wrapper">
              <div class="v-messages__message">
                {{ imagesError.mPicture[0] }}
              </div>
            </div>
          </div>
        </div>

        <form-subheader title="Icon" />
        <form ref="formIcon" class="user_photo pa-1">
          <div
            v-if="!mIcon"
            class="text-center d-flex justify-center align-center "
            style="width: 100%; height: 120px;"
          >
            <img src="/img/empty-media.svg" alt="Select media file" />
          </div>
          <v-img v-else contain :src="mIcon" :height="240" />
          <div class="upload">
            <v-btn
              text
              dense
              :color="options.bgColor"
              :class="{ shadow: options.shadow }"
              small
              fab
              @click="$refs.icon.click()"
            >
              <input
                id="iconInput"
                ref="icon"
                type="file"
                accept="image/*"
                style="display: none"
                @change="filePick($event, 'icon')"
              />
              <v-icon :color="options.textColor">
                mdi-cloud-upload
              </v-icon>
            </v-btn>

            <!--            <v-btn-->
            <!--              v-if="isNew && mIcon"-->
            <!--              text-->
            <!--              dense-->
            <!--              :color="options.bgColor"-->
            <!--              :class="{ shadow: options.shadow }"-->
            <!--              class="ml-3"-->
            <!--              small-->
            <!--              fab-->
            <!--              type="reset"-->
            <!--              @click="clearFile('icon', $refs.formIcon)"-->
            <!--            >-->
            <!--              <v-icon :color="options.textColor">-->
            <!--                mdi-delete-->
            <!--              </v-icon>-->
            <!--            </v-btn>-->
          </div>
        </form>
        <div transition="scroll-y-transition">
          <div
            v-if="imagesError.mIcon.length > 0"
            class="v-messages theme--light error--text mt-2"
            role="alert"
          >
            <div class="v-messages__wrapper">
              <div class="v-messages__message">
                {{ imagesError.mIcon[0] }}
              </div>
            </div>
          </div>
        </div>

        <form-subheader title="Description" />
        <v-textarea
          v-model="localEntity.description"
          no-resize
          rows="1"
          auto-grow
        />
      </v-form>
    </template>
    <template slot="footer">
      <v-spacer />
      <v-btn text color="text-primary" @click="close">
        Cancel
      </v-btn>
      <v-btn text color="primary" depressed @click="save">
        Save
      </v-btn>
    </template>
  </layout>
</template>

<script>
import {
  computed,
  onMounted,
  reactive,
  ref,
  toRefs
} from '@vue/composition-api';
import { useAuth } from '@/modules/auth/api';
import { useLazyQuery, useMutation } from '@vue/apollo-composable';
import axios from 'axios';

import Layout from '@/components/popup/PopupLayoutDefault';
import SelectChips from '@/components/_base/SelectChips';

import schema from '@/modules/account/schema.graphql';

export default {
  name: 'EditUser',
  components: {
    SelectChips,
    Layout
  },
  setup(props, { emit }) {
    const types = ref([
      { id: 'USER', name: 'Person' },
      { id: 'APP', name: 'Application' }
    ]);
    const type = ref('person');
    const { user, loadUser } = useAuth();
    const entity = ref({});
    const localEntity = ref({ ...entity.value, userGroups: [] });

    const { mutate: updateUser } = useMutation(schema.updateUser);
    const { mutate: addToGroup } = useMutation(schema.addToGroup);
    const { mutate: deleteUsersGroup } = useMutation(schema.deleteUsersGroup);

    const groupsApi = useLazyQuery(
      schema.loadGroups,
      {},
      {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only'
      }
    );

    groupsApi.onResult(() => {
      state.filteredGroups = groupsApi.result.value.userGroups
        .filter(item => !item.isSystem)
        .map(item => ({
          id: item.id,
          name: item.groupName
        }));
    });

    const getPicture = type => {
      if (entity.value[type]) {
        return `${window.__pixelConfig.APP_MEDIA_SERVER}/download/${
          entity.value[type]
        }/${localStorage.getItem('authToken')}`;
      } else {
        return '';
      }
    };
    const state = reactive({
      filteredGroups: [],
      loadFile: false,
      mPicture: getPicture('mPicture'),
      mIcon: getPicture('mIcon'),
      mPictureFile: null,
      mPictureFileProgress: 0,
      mIconFile: null,
      mIconFileProgress: 0,
      formDataPicture: null,
      formDataIcon: null,
      errors: {},
      defaultEditorgroup: null,
      defaultReadergroup: 'ffffffff-ffff-ffff-ffff-ffffffffffff',
      defaultUsergroup: 'ffffffff-ffff-ffff-ffff-ffffffffffff',
      imagesError: {
        mPicture: [],
        mIcon: []
      },
      options: {
        bgColor: 'transparent',
        textColor: 'white',
        showTitle: false,
        shadow: true
      }
    });
    const errors = ref({});
    const rules = ref({
      required: value => !!value || 'Required.',
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || 'Invalid e-mail.';
      }
    });

    const handleDropChip = (array, id, typeOperation) => {
      if (typeOperation === 'id') {
        array.splice(
          array.findIndex(i => i.id === id),
          1
        );
      } else {
        array.splice(
          array.findIndex(i => i === id),
          1
        );
      }
    };

    const groupsToBeRemoved = () => {
      const needRemove = [];
      if (entity.value?.id) {
        const oldGroups = entity.value.usersToGroups.map(
          item => item.userGroup.id
        );

        const newGroups = localEntity.value.usersToGroups.map(item => item.id);

        oldGroups.forEach(item => {
          if (!newGroups.includes(item)) {
            needRemove.push(item);
          }
        });
      }
      return needRemove;
    };

    const groupsToBeAdded = () => {
      let diff = [];
      if (entity.value?.id) {
        const oldGroups = entity.value.usersToGroups.map(
          item => item.userGroup.id
        );

        const newGroups = localEntity.value.usersToGroups.map(item => item.id);

        diff = newGroups.filter(i => !oldGroups.includes(i));
      }
      return diff;
    };

    function close() {
      emit('close');
    }

    function save() {
      if (localEntity.value.password !== localEntity.value.password2) {
        errors.value = {
          password: 'Passwords must be the same',
          password2: 'Passwords must be the same'
        };
      } else {
        this.uploadFiles().then(r => {
          state.loadFile = false;
          userUpdate({
            ...localEntity.value,
            type: type.value,
            groupsToBeRemoved: groupsToBeRemoved(),
            groupsToBeAdded: groupsToBeAdded(),
            editorgroup: user.value.editorgroup,
            defaultEditorgroup: state.defaultEditorgroup,
            defaultReadergroup: state.defaultReadergroup,
            defaultUsergroup: state.defaultUsergroup,
            mPicture:
              r.find(el => el.type === 'picture')?.id || entity.value.mPicture,
            mIcon: r.find(el => el.type === 'icon')?.id || entity.value.mIcon
          });
        });
      }
    }
    const clearFile = (type, isResetForm) => {
      if (type === 'photo') {
        state.imagesError.mPicture = [];
        state.mPicture = '';
      } else {
        state.imagesError.mIcon = [];
        state.mIcon = '';
      }
      if (isResetForm) isResetForm.reset();
    };
    const filePick = (e, type) => {
      const file = e.target.files[0];
      if (file) {
        const size = (file.size / 1024 / 1024).toFixed(3);

        if (size > 4) {
          if (type === 'photo') {
            state.imagesError.mPicture.push('File more than 4MB size');
          } else {
            state.imagesError.mIcon.push('File more than 4MB size');
          }
        } else if (!file.type.includes('image')) {
          if (type === 'photo') {
            state.imagesError.mPicture.push('Not image file');
          } else {
            state.imagesError.mIcon.push('Not image file');
          }
        } else {
          if (type === 'photo') {
            state.formDataPicture = new FormData();
            state.formDataPicture.append('uploaded_file', file);
            state.mPicture = URL.createObjectURL(file);
            state.mPictureFile = file;
          } else {
            state.formDataIcon = new FormData();
            state.formDataIcon.append('uploaded_file', file);
            state.mIcon = URL.createObjectURL(file);
            state.mIconFile = file;
          }
        }
      }
    };
    const uploadFiles = () => {
      let promises = [];
      if (state.formDataPicture || state.formDataIcon) {
        state.loadFile = true;
        if (state.formDataPicture) {
          promises.push(
            axios
              .request({
                method: 'POST',
                url: `${
                  window.__pixelConfig.APP_MEDIA_SERVER
                }/upload/0/${localStorage.getItem('authToken')}`,
                headers: {
                  'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: function(progressEvent) {
                  this.mPictureFileProgress = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                },
                data: state.formDataPicture
              })
              .then(res => {
                return {
                  id: res.data,
                  type: 'picture'
                };
              })
          );
        }
        if (state.formDataIcon) {
          promises.push(
            axios
              .request({
                method: 'POST',
                url: `${
                  window.__pixelConfig.APP_MEDIA_SERVER
                }/upload/0/${localStorage.getItem('authToken')}`,
                headers: {
                  'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: function(progressEvent) {
                  state.mIconFileProgress = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                },
                data: state.formDataIcon
              })
              .then(res => {
                return {
                  id: res.data,
                  type: 'icon'
                };
              })
          );
        }
      }
      return Promise.all(promises);
    };
    const removeFalsy = obj => {
      let newObj = {};
      Object.keys(obj).forEach(prop => {
        if (obj[prop] || prop === 'enabled') {
          newObj[prop] = obj[prop];
        }
      });
      return newObj;
    };

    const groups = computed(() => {
      if (groupsApi.result?.value?.userGroups.length) {
        return groupsApi.result?.value?.userGroups.map(item => ({
          id: item.id,
          name: item.groupName
        }));
      }
      return [];
    });

    onMounted(async () => {
      entity.value = user.value;

      await groupsApi.load();

      localEntity.value = {
        ...entity.value,
        userGroups: [],
        usersToGroups: []
      };

      if (entity.value?.id) {
        state.defaultEditorgroup = entity.value.defaultEditorgroup;
        state.defaultReadergroup = entity.value.defaultReadergroup;
        state.defaultUsergroup = entity.value.defaultUsergroup;

        let groups = entity.value.usersToGroups.map(item => ({
          id: item.userGroup.id,
          name: item.userGroup.groupName
        }));

        localEntity.value.usersToGroups.push(...groups);
      }

      if (entity.value.mTags) {
        localEntity.value.tags = entity.value.mTags;
      }
    });

    const userUpdate = e => {
      updateUser({
        input: {
          id: user.value.id,
          patch: {
            description: e.description,
            mName: e.mName,
            mEmail: e.mEmail,
            mTags: e.mTags,
            mPicture: e.mPicture || null,
            mIcon: e.mIcon || null,
            mPhone: e.mPhone,
            mExternalId: e.mExternalId
          }
        }
      })
        .then(() => {
          const promises = [];
          e.groupsToBeAdded.forEach(i => {
            promises.push(
              addToGroup({
                input: {
                  usersToGroup: {
                    userId: user.value.id,
                    userGroupId: i?.id || i
                  }
                }
              })
            );
          });
          e.groupsToBeRemoved.forEach(i => {
            promises.push(
              deleteUsersGroup({
                input: {
                  id: i
                }
              })
            );
          });
          return Promise.all(promises);
        })
        .then(() => {
          // return loadData(context.root.$route.params.id);
          return loadUser();
        })
        .then(() => {
          // entityComposition.handleClose();
          close();
        })
        .finally(() => {
          // entityComposition.popupLoading.value = false;
        });
    };

    return {
      ...toRefs(state),
      type,
      types,
      tags: [],
      groups,
      localEntity,
      errors,
      rules,
      save,
      close,
      groupsToBeRemoved,
      uploadFiles,
      removeFalsy,
      clearFile,
      getPicture,
      filePick,
      handleDropChip
    };
  }
};
</script>

<style lang="scss" scoped>
.user_photo {
  position: relative;
  background-color: #dbdbdb;
  background-position: center;
  min-height: 120px;
}

.upload {
  position: absolute;
  top: 15px;
  right: 15px;
}

.shadow {
  background-color: rgba(0, 0, 0, 0.44) !important;
  border-color: rgba(0, 0, 0, 0.44) !important;
}
</style>
